import React from 'react';
import styled from 'styled-components';

class Input extends React.Component {
  componentDidUpdate() {
    if (this.props.meta.error && this.props.showSnackBar) {
      this.props.showSnackBar(this.props.meta.error, 3000, false);
    }
  }

  render() {
    const {
      input, label, icon, type, autoComplete, autoFocus,
    } = this.props;
    const { value } = input;
    const { error, touched } = this.props.meta;

    if (icon) {
      return (
        <StyledInput error={touched && error}>
          <input
            id={label}
            type={type}
            autoFocus={autoFocus}
            autoComplete={autoComplete || 'off'}
            {...input}
            className={value.length ? 'not-empty' : 'empty'}
          />
          <div className="input-icon">
            <img className="input-icon__image" src={icon} alt="icon" decoding="async" loading="lazy" />
          </div>
          <label htmlFor={label}>{label}</label>
          <div className="input-line" />
        </StyledInput>
      );
    }

    return (
      <StyledInput className="default" error={error && touched}>
        <input
          {...input}
          className={value.length ? 'not-empty' : 'empty'}
        />
        <label htmlFor={label}>{label}</label>
      </StyledInput>
    );
  }
}

const StyledInput = styled.div`
  position: relative;
  height: 60px;
  box-sizing: border-box;
  background-color: #1C1C1C;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid ${(props) => (props.error ? 'red' : '#313131')};

  label {
    position: absolute;
    top: 50%;
    left: 77px;
    font-size: 12px;
    color: #616161;
    transform: translateY(-50%);
    transition: all 0.15s ease-in-out;
    transform-origin: left center;
    pointer-events: none;
  }

  input {
    height: 100%;
    background: inherit;
    border: none;
    width: 100%;
    padding-left: 77px;
    color: #8e8e8e;
    font-size: 16px;
    padding-top: 17px;
    padding-right: 15px;
    outline-color: ${(props) => (props.error ? 'red' : 'transparent')};

    &:focus ~ label,
    &.not-empty ~ label {
      transform: scale(0.85) translateY(-150%);
      color: ${(props) => (props.error ? 'red' : '#616161')};
    }
  }

  .input-line {
    position: absolute;
    top: 50%;
    left: 60px;
    height: 75%;
    width: 2px;
    transform: translateY(-50%);
    background-image: linear-gradient(to top, transparent 66.67%, #313131 33.33%);
    background-size: 100% 8px;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);

    &__image {
      opacity: .5;
    }
  }

  &.default {
    input {
      padding-left: 15px;
    }

    label {
      left: 77px;
    }
  }

  .icon {
    display: inline-block;
    position: relative;
  }
    
  .empty {

  }

  @media all and (min-width: 360px) {
    label {
      font-size: 15px;
    }
  }

  @media all and (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

export default Input;
