/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { helmetJsonLdProp, JsonLd } from 'react-schemaorg';
import ProductModalHeader from './productModal/ProductModalHeader';
import ProductsGrid from '../shared/ProductsGrid';
import keyByLang from '../../modules/shared/keyByLang';

import {
  activeLike, like, modalClose, shareIcon,
} from '../../assets/icons/svgIcons';

import filterAddonsByTime from '../../modules/shared/filterAddonsByTime';
import imageEmpty from '../../assets/icons/empty_cart.svg';
import checkIfItBusiness from '../../modules/shared/checkIfItBusiness';

class ProductItemModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      activeTab: {},
      chosenAddItemFree: [],
      chosenAddItemPaid: [],
      showShare: false,
    };

    this.isVisitedTabs = [0];
  }

  componentDidMount() {
    if ('share' in navigator) {
      this.setState({
        showShare: true,
      });
    }
  }

  getTimeToCompare = () => {
    const hours = new Date().getHours();
    let minutes = new Date().getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return +`${hours}${minutes}`;
  };

  onChangeHandleClickOnFreeItem = (event, tabId, productId) => {
    const IsclickedItemChecked = event.target;
    const clickedItemVal = productId;

    if (IsclickedItemChecked && this.state.chosenAddItemFree[0]) {
      this.setState((prevState) => {
        let matchIndex = null;
        const arrCopy = prevState.chosenAddItemFree;

        arrCopy.forEach((item, index) => {
          if (item.tabid === tabId) {
            matchIndex = index;
          }
        });
        if (matchIndex === 0 || matchIndex > 0) {
          arrCopy.splice(matchIndex, 1);
        }
        return {
          chosenAddItemFree: matchIndex
            ? [...arrCopy, { tabid: tabId, clickedItemVal }]
            : [...prevState.chosenAddItemFree, { tabid: tabId, clickedItemVal }],
        };
      });
    } else {
      this.setState((prevState) => ({
        chosenAddItemFree: [...prevState.chosenAddItemFree, { tabid: tabId, clickedItemVal }],
      }));
    }
  };

  onChangeHandleClickOnPaidItem = (productId) => {
    this.setState((prevState) => ({
      chosenAddItemPaid: ((!prevState.chosenAddItemPaid.includes(productId))
        ? ([...prevState.chosenAddItemPaid, productId])
        : (prevState.chosenAddItemPaid.filter((val) => (val !== productId)))),
    }));
  };

  tabOnClickHandler = (data) => {
    if (!this.isVisitedTabs.includes(data) && this.state.activeTabIndex < data) {
      this.goToTab(this.state.activeTabIndex + 1);
      this.isVisitedTabs.push(this.state.activeTabIndex + 1);
    } else if (this.isVisitedTabs.includes(data) || this.state.activeTabIndex > data) {
      this.goToTab(data);
    }
  };

  goToTab = (targetTabIndex) => {
    const modalWrap = document.querySelector('.product-wrap');
    this.setState({
      activeTabIndex: targetTabIndex,
      activeTab: targetTabIndex > 0 ? this.props.productData.tabs[targetTabIndex - 1] : {},
    });
    modalWrap.scrollTop = 0;
  };

  isInFavorites = (product) => !!this.props.user.favourites
    .find((favourite) => +favourite === +product.id);

  shareProduct = () => {
    navigator.share({
      title: this.props.productData[keyByLang('title', this.props.currentLanguage)],
      text: this.props.productData[keyByLang('description', this.props.currentLanguage)],
      url: window.location.href,
    });
  }

  render() {
    const {
      productData,
      allergensData,
      getProductTabsData,
      ingredientsData,
      currentLanguage,
      mainTasteData,
      setOrLunchBoxInnerProducts,
      toCartClickHandler,
      showSnackBar,
      user,
      removeFromFavourites,
      addToFavourites,
      categories,
      translate,
      goBack,
    } = this.props;

    const showAddToCartButton = checkIfItBusiness(productData.product_category_id, categories);

    const {
      tabs,
      is_active,
      // id,
    } = productData;

    let isProductAvailable = true;
    const time = this.getTimeToCompare();

    if (productData.available_since && productData.available_till) {
      const since = +productData.available_since.split(':').join('');
      const till = +productData.available_till.split(':').join('');
      isProductAvailable = time > since && time < till;
    }

    let tabsToShow = [];

    if (tabs && tabs[0]) {
      tabsToShow = filterAddonsByTime(tabs);
    }

    const descriptionText = productData[keyByLang('description', currentLanguage)];
    const title = productData[keyByLang('title', currentLanguage)];
    const parentCatTitle = productData[keyByLang('parent_title', currentLanguage)];

    // const freeTabsArr = tabsToShow ? tabsToShow
    //   .filter(item => Boolean(parseInt(item.are_addons_free, 10)) === true) : null;

    // ingredientsItems - shows if render simple product
    const ingredientsItems = ingredientsData ? ingredientsData
      .map((item) => <li key={item.id}>{currentLanguage === 'ru' ? item.title_ru : item.title_ua}</li>) : null;

    // innerProductsItems - shows if render only set type product
    const innerProductsItems = setOrLunchBoxInnerProducts ? setOrLunchBoxInnerProducts
      .map((item) => <li key={item.id}>{currentLanguage === 'ru' ? item.title_ru : item.title_ua}</li>) : null;

    const mainTasteItems = mainTasteData ? mainTasteData
      .map((item) => <li key={item.id}>{currentLanguage === 'ru' ? item.title_ru : item.title_ua}</li>) : null;

    const ingridientsToDisplay = (setOrLunchBoxInnerProducts && setOrLunchBoxInnerProducts[0])
      ? innerProductsItems
      : ingredientsItems;

    const otherTabContent = tabsToShow && tabsToShow[0] ? tabsToShow
      .find((tab, tabIndex) => this.state.activeTabIndex === tabIndex + 1) : null;

    const isTabFree = otherTabContent && otherTabContent.are_addons_free;

    let isFavourite = false;

    if (user && user.id) {
      isFavourite = this.isInFavorites(productData);
    }

    const activeTabContent = otherTabContent ? (
      <div className="modal-main">
        <ProductsGrid
          products={getProductTabsData(JSON.parse(otherTabContent.addons))}
          isTabFree={otherTabContent.are_addons_free}
          onChangeHandleClickFree={this.onChangeHandleClickOnFreeItem}
          onChangeHandleClickPaid={this.onChangeHandleClickOnPaidItem}
          currentState={this.state}
          tabid={otherTabContent.id}
        />
      </div>
    ) : null;

    let portionQuantity;
    if (productData) {
      portionQuantity = productData.portion_quantity;
    }
    // if (productData && (+productData.is_set || +productData.is_lunchbox)) {
    //   portionQuantity = productData.products.reduce((res, prodId) => {
    //     const portion = +getProductById(prodId, categories).portion_quantity;
    //     res = portion > 0 ? res + portion : res;
    //     return res;
    //   }, 0);
    // }

    const mainContent = (this.state.activeTabIndex === 0) ? (
      <>
        <div className="product" data-product-id={productData.id}>
          {user && user.id ? (
            <div
              className="product__like"
              onClick={isFavourite
                ? () => removeFromFavourites(productData.id)
                : () => addToFavourites(productData.id)}
            >
              {isFavourite ? activeLike : like}
            </div>
          ) : null}
          <div className="product__image-wrap">
            <img
              className="product__image"
              src={productData.image_filename ? productData.image_filename : imageEmpty}
              alt={`${title} заказать суши`}
              title={`${title} заказать суши ${translate('OdessaTown')} Budusushi`}
              decoding="async"
              loading="lazy"
            />
            {this.state.showShare ? (
              <button type="button" className="product__share" onClick={() => this.shareProduct()}>
                {shareIcon}
              </button>
            ) : null}
          </div>
          <div className="product__content">
            <div className="product__price-wrap">
              <div className="product__price">
                {productData.price}
                {' '}
                грн
              </div>
              <div className="product__break-line" />
              <div className="product__pieces">
                {parseInt(portionQuantity, 10) !== 0 ? portionQuantity : 1}
                {' '}
                шт
              </div>
              {parseInt(productData.weight, 10) ? (
                <>
                  <div className="product__break-line" />
                  <p className="product__portion">
                    {productData.weight}
                    {' '}
                    {translate('Gram')}
                  </p>
                </>
              ) : null}
            </div>
            <hr className="solid is-visible" />
            <div className="product__section">
              <div className="product__title">{translate('Composition')}</div>
              <ul className="product__composition">
                {ingridientsToDisplay}
              </ul>
              <hr className="solid" />
            </div>
            <p className="product__description">{descriptionText}</p>
            <hr className="solid" />
            <div className="product__section">
              <div className="product__title product__title--taste product__title--padded">{translate('BasicFlavour')}</div>
              <ul className="product__taste">{mainTasteItems}</ul>
            </div>
          </div>
        </div>
        {/* <hr className="solid is-visible" /> */}
        {/* <AllergensList */}
        {/*  allergensData={allergensData} */}
        {/*  currentLanguage={currentLanguage} */}
        {/*  translate={translate} */}
        {/* /> */}
      </>
    ) : (
      <div className="modal-main">{activeTabContent}</div>
    );

    const meta = Object
      .entries(productData)
      .filter(([key]) => key.includes('meta') && key.includes(`${currentLanguage}`) && !key.includes('image'));

    const metaByLang = meta.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const getMetaValue = (keyWord) => {
      let value;
      Object.keys(metaByLang).forEach((item) => {
        if (item.includes(keyWord)) {
          value = metaByLang[item];
        }
      });
      return value;
    };

    return (
      <StyledProductContent className="product-wrap">
        <Helmet
          script={[
            helmetJsonLdProp({
              '@context': 'https://schema.org',
              '@type': 'Product',
              name: productData.name,
              description: productData[`description_${currentLanguage}`],
              image: productData.image_filename || undefined,
              offers: {
                '@type': 'Offer',
                availability: 'http://schema.org/InStock',
                price: productData.price,
                priceCurrency: 'UAH',
              },
            }),
          ]}
        >
          <title>{getMetaValue('title')}</title>
          <meta name="title" content={getMetaValue('title')} />
          <meta name="description" content={getMetaValue('description')} />
          <meta name="keywords" content={getMetaValue('keywords')} />
        </Helmet>
        <ProductModalHeader
          parentCatTitle={parentCatTitle}
          title={title}
          tabs={tabsToShow}
          tabOnclickHandler={this.tabOnClickHandler}
          product={productData}
          isTabFree={isTabFree}
          choosedAddItemFree={this.state.chosenAddItemFree}
          choosedAddItemPaid={this.state.chosenAddItemPaid}
          activeTab={this.state.activeTab}
          activeTabFromState={this.state.activeTabIndex}
          toCartClickHandler={toCartClickHandler}
          showSnackBar={showSnackBar}
          currentLanguage={currentLanguage}
          isProductAvailable={isProductAvailable}
          translate={translate}
          isActiveProduct={is_active === '1' && showAddToCartButton}
        />
        <button
          type="button"
          className="product__back"
          onClick={goBack}
        >
          {modalClose}
        </button>
        {mainContent}
      </StyledProductContent>
    );
  }
}

const StyledProductContent = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;

  max-width: 1230px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  
  .product {
    padding-top: 2.4rem;
    display: flex;
    color: var(--text-color);
    flex-direction: column;
    position: relative;
    
    &__like {
      position: absolute;
      top: 2rem;
      left: 1rem;
    }
    
    &__back {
      position: absolute;
      top: 1rem;
      right: 1rem;
      border: none;
      background-color: transparent;
      border-radius: 50%;
      padding: 0.5rem;
      transition: var(--transition-time);
      outline: none;
      z-index: 2;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
    
    &__image-wrap {
      flex-basis: 50%;
      flex-shrink: 0;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    
    &__share {
      position: absolute;
      bottom: 0;
      right: 0;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0.5rem;
    }
    
    &__image {
      max-width: 100%;
      max-height: 360px;
      width: 100%;
      object-fit: contain;
    }
    
    &__content {
      padding: 10px;
      flex-basis: 50%;
    }
    
    &__price-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5rem 0 0 0;
    }
    
    &__price {
      font-size: 1.6rem;
      font-weight: 700;
      color: var(--active-text-color);
    }
    
    &__break-line {
      width: 2px;
      height: 14px;
      background-image: linear-gradient(to top, #8E8E8E 20%, transparent 20%, transparent 80%, #8E8E8E 80%);
      background-size: 2px 7px;
      margin: 0 8px;
    }
    
    &__pieces {
      font-size: 1.2rem;
    }
    
    &__title {
      text-transform: uppercase;
      font-size: .8rem;
      font-weight: 700;
      text-align: left;
      
      &--padded {
        padding: 1rem 0;
      }
      
      &--taste {
        text-align: center;
      }
    }
    
    &__composition {
      padding: 1rem 0;
      color: var(--active-text-color);
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      
      & > li {
        flex-basis: 50%;
        padding: 2.5px 20px 2.5px 0;
      }
    }
    
    &__description {
      padding: 1rem 0;
      text-align: center;
    }
    
    &__taste {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      
      & > li {
        display: flex;
        flex-direction: row;
        align-items: center;
      
        &:not(:last-child)::after {
          content: '';
          display: block;
          position: relative;
          width: 1px;
          height: 100%;
          background-color: var(--text-color);
          margin: 0 10px;
        }
      }
    }
  }
  
  .is-visible {
    display: block;
    margin: 1.5rem 0;
  }
  
@media all and (min-width: 480px) {
  .product {
    &__like {
      display: none;
    }
  }
}
  
@media all and (min-width: 768px) {
  text-align: left;
  
  .product {
    flex-direction: row;
    
    &__image-wrap {
      justify-content: flex-start;
    }
    
    &__image {
      max-height: 400px;
    }
    
    &__price-wrap {
      justify-content: flex-start;
      padding: 0 0 2.4rem 0;
    }
    
    &__title--taste {
      text-align: left;
    }
    
    &__taste {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      
      & > li {
        display: flex;
        flex-direction: row;
      
        &:not(:last-child)::after {
          content: '';
          display: block;
          position: relative;
          width: 1px;
          height: 100%;
          background-color: var(--text-color);
          margin: 0 10px;
        }
      }
    }
    
    &__description {
      text-align: justify;
    }
  }
  
  .is-visible {
    display: none;
  }
}
  
@media all and (min-width: 1024px) {
  .product {
  
    &__image {
      max-height: 480px;
    }
  }
}
`;

export default ProductItemModal;
