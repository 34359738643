/* eslint-disable no-unused-expressions,no-unused-vars */
import { push } from 'connected-react-router';
import { setActiveLanguage, initialize } from 'react-localize-redux';
import languages from '../utils/languages';

import * as types from './actionTypes';
import CategoryApi from '../api/CategoryApi';
import AllergrensApi from '../api/AllergensApi';
import IngredientsApi from '../api/IngredientsApi';
import HomeStaticDataApi from '../api/HomeStaticDataApi';
import CartApi from '../api/CartApi';
import StoresApi from '../api/StoresApi';
import AuthApi from '../api/AuthApi';
import UserApi from '../api/UserApi';
import SettingsApi from '../api/SettingsApi';
import NewsApi from '../api/NewsApi';
import PromotionsApi from '../api/PromotionsApi';
import bestSetsProductsList from '../modules/shared/bestSetsProductsList';
import FaqApi from '../api/FaqApi';
import CheckoutApi from '../api/CheckoutApi';
import AccessoriesApi from '../api/AccessoriesApi';
import TextPagesApi from '../api/TextPagesApi';
import HomeBannerApi from '../api/HomeBannerApi';
import ProductTagsApi from '../api/ProductTagsApi';
import AddressApi from '../api/AddressApi';
import VacanciesApi from '../api/VacanciesApi';
import ResumesApi from '../api/ResumesApi';
import SeoHomePageApi from '../api/SeoHomePageApi';
import FranchiseApi from '../api/FranchiseApi';
import CitiesApi from '../api/CitiesApi';
import { ADDED_PRODUCT, SET_IS_POPUP_ACTIVE, SET_ON_THANK_YOU_PAGE } from './actionTypes';

export function updateUserSuccess(data) {
  return { type: types.UPDATE_USER_SUCCESS, payload: data };
}

export function getUserSuccess(data) {
  return { type: types.GET_USER_SUCCESS, payload: data };
}

export function logoutUserSuccess(data) {
  return { type: types.LOGOUT_USER_SUCCESS, payload: data };
}

export function getWaitSmsSuccess(data) {
  return { type: types.GET_WAIT_SMS_SUCCESS, payload: data };
}

export function getSmsIsValidSuccess(data) {
  return { type: types.GET_SMS_VALID_SUCCESS, payload: data };
}

export function getAllergensSuccess(allergens) {
  return { type: types.GET_ALLERGENS_SUCCESS, payload: allergens };
}

export function getIngredientsSuccess(ingridients) {
  return { type: types.GET_INGREDIENTS_SUCCESS, payload: ingridients };
}

export function getCategoriesSuccess(categories) {
  return { type: types.GET_CATEGORIES_SUCCESS, payload: categories };
}

export function getTopBannerDataSuccess(topBannerContent) {
  return { type: types.GET_TOPBANNER_DATA_SUCCESS, payload: topBannerContent };
}

export function getBestSetsSuccess(bestSetsContent) {
  return { type: types.GET_BESTSETS_DATA_SUCCESS, payload: bestSetsContent };
}

export function getBonusClubBannerSuccess(bonusClubBannerContent) {
  return { type: types.GET_BONUSCLUB_BANNER_DATA_SUCCESS, payload: bonusClubBannerContent };
}

export function getBestSetsProductsListSuccess(data) {
  return { type: types.GET_BESTSETS_PRODUCTS_DATA_SUCCESS, payload: data };
}

export function getCartSuccess(data) {
  return { type: types.GET_CART_SUCCESS, payload: data };
}

export function getStoresSuccess(data) {
  return { type: types.GET_STORES_SUCCESS, payload: data };
}

export function getOrdersHistorySuccess(data) {
  return { type: types.GET_ORDERS_HISTORY_SUCCESS, payload: data };
}

export function getOrderStatusesSuccess(data) {
  return { type: types.GET_ORDER_STATUSES_SUCCESS, payload: data };
}

export function getPaymentMethodsSuccess(data) {
  return { type: types.GET_PAYMENT_METHODS_SUCCESS, payload: data };
}

export function getDeliveryMethodsSuccess(data) {
  return { type: types.GET_DELIVERY_METHODS_SUCCESS, payload: data };
}

export function getNewsSuccess(data) {
  return { type: types.GET_NEWS_SUCCESS, payload: data };
}

export function getPromotionSuccess(data) {
  return { type: types.GET_PROMOTIONS_SUCCESS, payload: data };
}

export function getFaqSuccess(data) {
  return { type: types.GET_FAQ_SUCCESS, payload: data };
}

export function getDeliveryZonesSuccess(data) {
  return { type: types.GET_DELIVERY_ZONES_SUCCESS, payload: data };
}

export function showSnackBarSuccess(data) {
  return { type: types.SHOW_SNACKBAR_SUCCESS, payload: data };
}

export function hideSnackBarSuccess(data) {
  return { type: types.HIDE_SNACKBAR_SUCCESS, payload: data };
}

export function setDeferredOrderDataSuccess(data) {
  return { type: types.SET_DEFERRED_ORDER_DATA, payload: data };
}

export function getSettingsSuccess(data) {
  return { type: types.GET_SETTINGS_SUCCESS, payload: data };
}

export function getHeadersSuccess(data) {
  return { type: types.GET_HEADERS_SUCCESS, payload: data };
}

export function setOrderSuccess(data) {
  return { type: types.SET_ORDER, payload: data };
}

export function setInitRequestSuccess(data) {
  return { type: types.SET_INIT, payload: data };
}

export function orderCreateSuccess(data) {
  return { type: types.ORDER_CREATE_SUCCESS, payload: data };
}

export function getAccessoriesSuccess(data) {
  return { type: types.GET_ACCESSORIES_SUCCESS, payload: data };
}

export function setCartToCheckoutStepsSuccess(data) {
  return { type: types.SET_CARTTOCHECKOUTSTEPS_SUCCESS, payload: data };
}

export function getTextPagesSuccess(data) {
  return { type: types.GET_TEXT_PAGES, payload: data };
}

export function getHomeBannerDataSuccess(data) {
  return { type: types.GET_HOMEBANNER_DATA_SUCCESS, payload: data };
}

export function getProductTagsSuccess(data) {
  return { type: types.GET_PRODUCT_TAGS, payload: data };
}

export function setActiveCatalogLinkSuccess(data) {
  return { type: types.SET_ACTIVE_CATALOG_LINK_SUCCESS, payload: data };
}

export function setFavourites(data) {
  return { type: types.SET_FAVOURITES, payload: data };
}

export function getAccessoriesTypesSuccess(data) {
  return { type: types.GET_ACCESSORIES_TYPES_SUCCESS, payload: data };
}

export function setSeparatedAccessoriesSuccess(data) {
  return { type: types.SET_SEPARATED_ACCESSORIES, payload: data };
}

export function setAccessoriesUpdateSuccess(data) {
  return { type: types.SET_ACCESSORIES_UPDATE_SUCCESS, payload: data };
}

export function getBonusDataSuccess(data) {
  return { type: types.GET_BONUS_SUCCESS, payload: data };
}

export function addDeliveryAddressSuccess(address) {
  return { type: types.ADD_DELIVERY_ADDRESS_SUCCESS, payload: address };
}

export function removeDeliveryAddressSuccess(addressId) {
  return { type: types.REMOVE_DELIVERY_ADDRESS_SUCCESS, payload: addressId };
}

export function updateDeliveryAddressSuccess(address) {
  return { type: types.UPDATE_DELIVERY_ADDRESS_SUCCESS, payload: address };
}

export function getVacanciesSuccess(data) {
  return { type: types.GET_VACANCIES_SUCCESS, payload: data };
}

export function getVacancyReviewsSuccess(data) {
  return { type: types.GET_VACANCY_REVIEWS_SUCCESS, payload: data };
}

export function setMobileSizeSuccess(data) {
  return { type: types.SET_MOBILE_SIZE_SUCCESS, payload: data };
}

export function setProductsFilterDataSuccess(data) {
  return { type: types.SET_PRODUCTS_FILTER_DATA, payload: data };
}

export function setProductScreenSizeSuccess(data) {
  return { type: types.SET_PRODUCT_SCREEN_SIZE, payload: data };
}

export function setFilteredCategoriesSuccess(data) {
  return { type: types.SET_FILTERED_CATEGORIES_SUCCESS, payload: data };
}

export function getSeoHomePageSuccess(data) {
  return { type: types.GET_SEO_HOME_PAGE_SUCCESS, payload: data };
}

export function updateDeliveryBlockSuccess(data) {
  return { type: types.UPDATE_DELIVERY_BLOCK_SUCCESS, payload: data };
}

export function updateDeliveryPosition(data) {
  return { type: types.UPDATE_DELIVERY_POSITION, payload: data };
}

export function setPromocode(data) {
  return { type: types.SET_PROMOCODE, payload: data };
}

export function setCities(data) {
  return { type: types.SET_CITIES, payload: data };
}

export function setBarShown(bool) {
  return { type: types.IS_BAR_SHOWN, payload: bool };
}

export function getAllergens() {
  return function (dispatch, getState) {
    return AllergrensApi.getAllergenList()
      .then((allergens) => {
        dispatch(getAllergensSuccess(allergens.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getIngredients() {
  return function (dispatch, getState) {
    return IngredientsApi.getIngredientsList()
      .then((ingredients) => {
        dispatch(getIngredientsSuccess(ingredients.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getCategories() {
  return function (dispatch, getState) {
    return CategoryApi.getCategoryList()
      .then((categories) => {
        dispatch(getCategoriesSuccess(categories.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getTopBannerData() {
  return function (dispatch, getState) {
    return HomeStaticDataApi.getTopBannerData()
      .then((TopBannersData) => {
        dispatch(getTopBannerDataSuccess(TopBannersData.data.topBanner));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getHomeBannerData() {
  return function (dispatch, getState) {
    return HomeBannerApi.getHomeBannerData()
      .then((banners) => {
        dispatch(getHomeBannerDataSuccess(banners.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getCities() {
  return function (dispatch, getState) {
    return CitiesApi.getCities()
      .then((cities) => {
        dispatch(setCities(cities.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getBestSetsData() {
  return function (dispatch, getState) {
    return HomeStaticDataApi.getBestSetsData()
      .then((bestSetsData) => {
        dispatch(getBestSetsSuccess(bestSetsData.data.bestSets));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getBonusClubBannerData() {
  return function (dispatch, getState) {
    return HomeStaticDataApi.getBonusClubBannerData()
      .then((bonusClubBannerData) => {
        dispatch(getBonusClubBannerSuccess(bonusClubBannerData.data.bonusClubBanner));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getCart() {
  return function (dispatch, getState) {
    return CartApi.getCart()
      .then((cart) => {
        dispatch(getCartSuccess(cart.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cartInc(product_id) {
  return function (dispatch, getState) {
    return CartApi.increase(product_id)
      .then((res) => {
        res.data
          ? CartApi.getCart()
            .then((cart) => {
              dispatch(getCartSuccess(cart.data));
            })
            .catch((error) => {
              throw error;
            })
          : null;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cartDec(product_id) {
  return function (dispatch, getState) {
    return CartApi.decrease(product_id)
      .then((res) => {
        res.data
          ? CartApi.getCart()
            .then((cart) => {
              dispatch(getCartSuccess(cart.data));
            })
            .catch((error) => {
              throw error;
            })
          : null;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cartAdd(product_id, addons) {
  return function (dispatch, getState) {
    return CartApi.add(product_id, addons)
      .then((res) => {
        res.data
          ? CartApi.getCart()
            .then((cart) => {
              dispatch(getCartSuccess(cart.data));
            })
            .catch((error) => {
              throw error;
            })
          : null;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cartRemove(cartItem_id) {
  return function (dispatch, getState) {
    return CartApi.remove(cartItem_id)
      .then((res) => {
        res.data
          ? CartApi.getCart()
            .then((cart) => {
              dispatch(getCartSuccess(cart.data));
            })
            .catch((error) => {
              throw error;
            })
          : null;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getBestSetsProductsList(arrayIds, categoriesObj) {
  return function (dispatch, getState) {
    return dispatch(getBestSetsProductsListSuccess(bestSetsProductsList(arrayIds, categoriesObj)));
  };
}

export function getStores() {
  return function (dispatch, getState) {
    return StoresApi.getStores()
      .then((stores) => {
        dispatch(getStoresSuccess(stores.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getSmsAuth(phoneNum, uid) {
  return function (dispatch, getState) {
    return AuthApi.getSmsWait(phoneNum, uid)
      .then((res) => {
        dispatch(getWaitSmsSuccess(res.data));
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getSmsIsValid(smsCode) {
  return async function (dispatch, getState) {
    return AuthApi.getSmsIsValid(smsCode)
      .then((res) => {
        CartApi.getCart()
          .then((cart) => {
            dispatch(getCartSuccess(cart.data));
            UserApi.getUser()
              .then((user) => {
                dispatch(getUserSuccess(user.data));
              });
          });
        return dispatch(getSmsIsValidSuccess(res.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getUser() {
  return function (dispatch, getState) {
    return UserApi.getUser()
      .then((user) => {
        dispatch(getUserSuccess(user.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateUser(obj) {
  return function (dispatch, getState) {
    return UserApi.update(obj)
      .then((res) => {
        res.data
          ? UserApi.getUser()
            .then((user) => {
              dispatch(getUserSuccess(user.data));
            })
            .catch((error) => {
              throw error;
            })
          : console.log('UPDATE NOT WORKS - res');
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateFullUserData(obj) {
  return function (dispatch, getState) {
    return UserApi.updateFullData(obj)
      .then((res) => {
        res.data
          ? UserApi.getUser()
            .then((user) => {
              dispatch(getUserSuccess(user.data));
            })
            .catch((error) => {
              throw error;
            })
          : console.log('UPDATE NOT WORKS - res');
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function logOutUser() {
  return function (dispatch, getState) {
    return UserApi.logOut()
      .then((res) => {
        dispatch(logoutUserSuccess('LOGOUTED'));
      }).then(() => {
        CartApi.getCart()
          .then((cart) => {
            dispatch(getCartSuccess(cart.data));
          });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getOrdersHistory() {
  return function (dispatch, getState) {
    return UserApi.getOrdersHistory()
      .then((orders) => {
        dispatch(getOrdersHistorySuccess(orders.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getLastOrder() {
  return UserApi.getLastOrder()
    .then((order) => order)
    .catch((error) => {
      throw error;
    });
}

export function getOrderStatuses() {
  return function (dispatch, getState) {
    return SettingsApi.getOrderStatuses()
      .then((statuses) => {
        dispatch(getOrderStatusesSuccess(statuses.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getPaymentMethods() {
  return function (dispatch, getState) {
    return SettingsApi.getPaymentMethods()
      .then((methods) => {
        dispatch(getPaymentMethodsSuccess(methods.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getDeliveryMethods() {
  return function (dispatch, getState) {
    return SettingsApi.getDeliveryMethods()
      .then((methods) => {
        dispatch(getDeliveryMethodsSuccess(methods.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function changeLocale(url) {
  return function (dispatch, getState) {
    dispatch(push(url));
  };
}

export function changeLanguage(code) {
  return function (dispatch, getState) {
    dispatch(setActiveLanguage(code));
  };
}

export function initializeLanguage(code) {
  return function (dispatch, getState) {
    dispatch(initialize(languages, { defaultLanguage: code }));
  };
}

export function getNews() {
  return function (dispatch, getState) {
    return NewsApi.getNews()
      .then((res) => {
        dispatch(getNewsSuccess(res.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getPromotions() {
  return function (dispatch, getState) {
    return PromotionsApi.getPromotions()
      .then((res) => {
        dispatch(getPromotionSuccess(res.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getFaq() {
  return function (dispatch, getState) {
    return FaqApi.getFaq()
      .then((res) => {
        dispatch(getFaqSuccess(res.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getDeliveryZones() {
  return function (dispatch, getState) {
    return StoresApi.getDeliveryZones()
      .then((res) => {
        dispatch(getDeliveryZonesSuccess(res.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function setDeferredOrderData(data) {
  return function (dispatch, getState) {
    dispatch(setDeferredOrderDataSuccess(data));
  };
}

export function getSettings() {
  return function (dispatch, getState) {
    return SettingsApi.getSettings()
      .then((res) => {
        dispatch(getHeadersSuccess(res.headers));
        dispatch(getSettingsSuccess(res.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function setOrder(data) {
  return function (dispatch, getState) {
    dispatch(setOrderSuccess(data));
  };
}

export function setInitRequest() {
  return function (dispatch, getState) {
    return SettingsApi.setInitRequest()
      .then((res) => {
        dispatch(setInitRequestSuccess(res));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function orderCreate(orderData) {
  return function (dispatch, getState) {
    return CheckoutApi.orderCreate(orderData)
      .then((res) => {
        dispatch(orderCreateSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function orderPreorder(orderData) {
  return function (dispatch, getState) {
    return CheckoutApi.orderPreorder(orderData)
      .then((res) => {
        dispatch(orderCreateSuccess(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getAccessories() {
  return function (dispatch, getState) {
    return AccessoriesApi.getAccessoriesList()
      .then((res) => {
        dispatch(getAccessoriesSuccess(res.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function setCartToCheckoutSteps(data) {
  return function (dispatch, getState) {
    dispatch(setCartToCheckoutStepsSuccess(data));
  };
}

export function getTextPages() {
  return function (dispatch, getState) {
    return TextPagesApi.getTextPages()
      .then((pages) => {
        dispatch(getTextPagesSuccess(pages.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getProductTags() {
  return function (dispatch, getState) {
    return ProductTagsApi.getTags()
      .then((productTags) => {
        dispatch(getProductTagsSuccess(productTags.data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function setActiveCatalogLink(data) {
  return function (dispatch, getState) {
    dispatch(setActiveCatalogLinkSuccess(data));
  };
}

export function addToFavourites(productId) {
  return function (dispatch, getState) {
    return UserApi.addToFavourites(productId)
      .then((res) => {
        res.data ? UserApi.getUser()
          .then((user) => {
            dispatch(getUserSuccess(user.data));
          })
          .catch((error) => {
            throw error;
          }) : null;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function removeFromFavourites(productId) {
  return function (dispatch, getState) {
    return UserApi.removeFromFavourites(productId)
      .then((res) => {
        res.data ? UserApi.getUser()
          .then((user) => {
            dispatch(getUserSuccess(user.data));
          })
          .catch((error) => {
            throw error;
          }) : null;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export const setSeparatedAccessories = (accessories) => (dispatch) => {
  dispatch(setSeparatedAccessoriesSuccess(accessories));
};

export const setAccessoriesUpdate = (bool) => (dispatch) => {
  dispatch(setAccessoriesUpdateSuccess(bool));
};

export const getAccessoriesTypes = () => (dispatch) => AccessoriesApi.getAccessoriesTypesList()
  .then((accessoriesTypes) => {
    dispatch(getAccessoriesTypesSuccess(accessoriesTypes.data));
  })
  .catch((error) => {
    throw error;
  });

export const showSnackBar = (content, time, isConfirm) => (dispatch) => {
  dispatch(showSnackBarSuccess({ content, time, isConfirm }));
};

export const hideSnackBar = (content, time, isConfirm) => (dispatch) => {
  dispatch(hideSnackBarSuccess({ content, time, isConfirm }));
};

export const getBonusData = () => (dispatch) => UserApi.getBonusData()
  .then((res) => {
    dispatch(getBonusDataSuccess(res.data));
  })
  .catch((error) => {
    throw error;
  });

export const updateUserName = (name) => (dispatch) => UserApi.updateUserName(name)
  .then((res) => dispatch(updateUserSuccess(res.data)))
  .catch((error) => {
    throw error;
  });

export const addDeliveryAddress = (address) => (dispatch) => AddressApi.addDeliveryAddress(address)
  .then((res) => dispatch(addDeliveryAddressSuccess(res.data)))
  .catch((error) => {
    throw error;
  });

export const removeAddress = (address) => (dispatch) => AddressApi.removeDeliveryAddress(address)
  .then((res) => dispatch(removeDeliveryAddressSuccess(res.data)))
  .catch((error) => {
    throw error;
  });

export const updateAddress = (address) => (dispatch) => AddressApi.updateDeliveryAddress(address)
  .then((res) => dispatch(updateDeliveryAddressSuccess(res.data)))
  .catch((error) => {
    throw error;
  });

export const sendReview = (data) => (dispatch) => FaqApi.sendReview(data)
  .then((res) => res.data)
  .catch((error) => {
    throw error;
  });

export const getVacancies = () => (dispatch) => VacanciesApi.getVacancies()
  .then((res) => dispatch(getVacanciesSuccess(res.data)))
  .catch((error) => {
    throw error;
  });

export const getVacancyReviews = () => (dispatch) => VacanciesApi.getVacancyReviews()
  .then((res) => dispatch(getVacancyReviewsSuccess(res.data)))
  .catch((error) => {
    throw error;
  });

export const sendCV = (data) => (dispatch) => ResumesApi.sendCV(data)
  .then((res) => res.data)
  .catch((error) => {
    throw error;
  });

export const setMobileSize = (data) => (dispatch) => {
  dispatch(setMobileSizeSuccess(data));
};

export const setProductsFilterData = (data) => (dispatch) => {
  dispatch(setProductsFilterDataSuccess(data));
};

export const setProductScreenSize = (data) => (dispatch) => {
  dispatch(setProductScreenSizeSuccess(data));
};

export const setFilteredCategories = (data) => (dispatch) => {
  dispatch(setFilteredCategoriesSuccess(data));
};

export const getPaymentPage = (data) => (dispatch) => CheckoutApi.getPaymentPage(data)
  .then((res) => res.data)
  .catch((error) => {
    throw error;
  });

export const getSeoHomePage = () => (dispatch) => SeoHomePageApi.getSeoHomePage()
  .then((res) => dispatch(getSeoHomePageSuccess(res.data)))
  .catch((error) => {
    throw error;
  });

export const updateDelivery = (data) => (dispatch) => {
  dispatch(updateDeliveryBlockSuccess(data));
};

export const attachDelivery = (data) => (dispatch) => {
  dispatch(updateDeliveryPosition(data));
};

export const addedProduct = (bool) => ({
  type: ADDED_PRODUCT,
  payload: bool,
});

export const sendFranchise = (data) => (dispatch) => FranchiseApi.sendFranchise(data)
  .then((res) => res.data)
  .catch((error) => {
    throw error;
  });

export const setOnThankYouPage = (bool) => ({
  type: SET_ON_THANK_YOU_PAGE,
  payload: bool,
});

export const setIsFaqPopupState = (bool) => ({
  type: SET_IS_POPUP_ACTIVE,
  payload: bool,
});
