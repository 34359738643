import React from 'react';
import styled from 'styled-components';

const TextArea = (props) => {
  const {
    input, label, height, meta,
  } = props;
  const { error, touched } = meta;
  return (
    <StyledTextArea
      id={label}
      height={height}
      placeholder={label}
      {...input}
      error={touched && error}
    />
  );
};

const StyledTextArea = styled.textarea`
    background-color: #1C1C1C;
    color: #616161;
    width: 100%;
    padding: 15px;
    border: 1px solid #313131;
    border-radius: 4px;
    resize: none;
    margin-bottom: 20px;
    border: 1px solid ${props => (props.error ? 'red' : '#313131')};
    height: ${({ height }) => (height || '120')}px;
`;


export default TextArea;
