/* eslint-disable react/no-danger,no-class-assign */
import React from 'react';
import styled from 'styled-components';
import { getActiveLanguage, getTranslate, Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions/actions';

import Input from '../shared/form/Input';
import MainBtn from '../shared/MainBtn';

import nameIcon from '../../assets/icons/form/name.svg';
import emailIcon from '../../assets/icons/form/email.svg';
import phoneIcon from '../../assets/icons/form/phone.svg';

class FranchiseForm extends React.Component {
  static validateForm(values) {
    const errors = {};
    const emailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const phoneStartPath = /^\+380(67|68|96|97|98|50|66|95|99|63|73|93)/;
    const searchLetters = /^[0-9]+$/;
    if (!values.userName || values.userName.length < 2) {
      errors.userName = <Translate id="EnterUserName" />;
    }
    if (!values.email) {
      errors.email = <Translate id="EnterEmail" />;
    }
    if (!emailRegExp.test(values.email)) {
      errors.email = <Translate id="IncorrectEmail" />;
    }
    if ((values.phone && values.phone.length < 4) || !values.phone) {
      values.phone = '+380';
    }
    if ((values.phone && values.phone.slice(0, 4) !== '+380') || !values.phone) {
      values.phone = `+38${values.phone}`;
    }
    if (!searchLetters.test(values.phone.slice(1))) {
      values.phone = `+${values.phone.replace(/[^0-9]/gim, '')}`;
    }
    if (values.phone && values.phone.length >= 6 && !phoneStartPath.test(values.phone)) {
      errors.phone = <Translate id="InvalidOperatorCodeMessage" />;
    }
    if (values.phone && values.phone.length > 13) {
      values.phone = values.phone.slice(0, 13);
    }
    if (values.phone && values.phone.length < 13) {
      errors.phone = <Translate id="NotFullPhoneNumber" />;
    }
    return errors;
  }

  constructor(props) {
    super(props);

    this.noSubmit = this.noSubmit.bind(this);
    this.sendFranchiseRequest = this.sendFranchiseRequest.bind(this);
  }

  noSubmit(e) {
    e.preventDefault();
    const errors = this.props.formData.syncErrors;
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      this.props.actions.showSnackBar(errors[keys[0]], 4000, false);
    }
  }

  sendFranchiseRequest(e) {
    e.preventDefault();
    const { translate } = this.props;
    const { values: { userName, email, phone } } = this.props.formData;
    const dataToSend = new FormData();
    const { dispatch } = this.props;
    dataToSend.append('FranshizaRequests[name]', userName);
    dataToSend.append('FranshizaRequests[email]', email);
    dataToSend.append('FranshizaRequests[phone]', phone.slice(phone.indexOf('0')));
    this.props.actions.sendFranchise(dataToSend).then((res) => {
      if (res && res.status) {
        this.props.actions.showSnackBar(translate('DataSentSuccessfully'), 4000, true);
        dispatch(reset('franchise-form'));
        window.scrollTo(0, 0);
      } else {
        this.props.actions.showSnackBar('Ошибка отправки данных', 4000, false);
      }
    });
  }

  render() {
    const {
      faq, stores, currentLanguage, activeTopic, valid, chooseStoreId, translate, formData
    } = this.props;

    return (
      <StyledFranchiseForm className="franchise" onSubmit={valid ? this.sendFranchiseRequest : this.noSubmit}>
        <h3 className="franchise__heading">{translate('FranchiseHeading')}</h3>
        <Field
          name="userName"
          component={Input}
          type="text"
          label={translate('NamePlaceholder')}
          icon={nameIcon}
        />
        <Field
          name="email"
          component={Input}
          type="email"
          label="Email"
          icon={emailIcon}
        />
        <Field
          name="phone"
          component={Input}
          type="phone"
          label={translate('PhoneNumber')}
          icon={phoneIcon}
        />
        <div className="submit-wrap">
          <MainBtn
            text={translate('SendCodeButton')}
            align="center"
            type="submit"
            minWidth="200px"
          />
        </div>
      </StyledFranchiseForm>
    );
  }
};

const StyledFranchiseForm = styled.form`
  --text-color: #8E8E8E;

  max-width: 640px;
  margin: 5rem auto;
  
  .franchise {
    &__heading {
      color: var(--text-color);
      text-transform: none;
      font-weight: 400;
      font-size: 1.4rem;
      text-align: center;
      padding-bottom: 2rem;
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

FranchiseForm = reduxForm({
  form: 'franchise-form',
  validate: FranchiseForm.validateForm,
  destroyOnUnmount: true,
})(FranchiseForm);

FranchiseForm = connect(
  state => (state.user ? ({
    initialValues: {
      email: state.user.email,
      userName: state.user.name,
      phone: state.user.username
    },
    formData: state.form['franchise-form'],
    translate: getTranslate(state.locale),
  }) : {
    translate: getTranslate(state.locale),
  }),
  mapDispatchToProps,
)(FranchiseForm);

export default withRouter(FranchiseForm);
