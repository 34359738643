import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProductHeaderTabItem from './ProductHeaderTabItem';
import MainBtn from '../../shared/MainBtn';
import * as actions from '../../../actions/actions';

const ProductModalHeader = ({
  parentCatTitle,
  title,
  tabs,
  tabOnclickHandler,
  activeTabFromState,
  product,
  isTabFree,
  choosedAddItemFree,
  choosedAddItemPaid,
  toCartClickHandler,
  showSnackBar,
  currentLanguage,
  isProductAvailable,
  isActiveProduct,
  activeTab,
  translate,
}) => {
  let isChoosedFreeItem;
  let isChoosedPaidItem;

  if (isTabFree === '0' && activeTab.addons) {
    isChoosedPaidItem = choosedAddItemPaid.filter((item) => activeTab.addons.indexOf(item) > -1);
  }

  if (isTabFree === '1' && activeTab.addons) {
    isChoosedFreeItem = choosedAddItemFree.filter((item) => item.tabid === activeTab.id);
  }

  const choosedAddons = [].concat(choosedAddItemPaid);
  choosedAddItemFree.map((item) => {
    choosedAddons.push(item.clickedItemVal);
  });

  const tabsCountSum = tabs && tabs[0] ? tabs.length + 1 : 0;

  let isTabsBlocked = false;

  if (activeTabFromState > 0) {
    isTabsBlocked = +isTabFree && !isChoosedFreeItem[0];
  }

  const mainButtonData = {
    callback: () => showSnackBar('Something wrong happened!', 3000, false),
    text: translate('AddToCartButton'),
  };

  if (isTabFree === '0' && !isChoosedPaidItem[0]) {
    mainButtonData.text = translate('NoThanks');
  } else if (tabsCountSum > 0 && activeTabFromState < tabsCountSum - 1) {
    mainButtonData.text = translate('NextButton');
  }

  if (!isProductAvailable) {
    mainButtonData.callback = () => showSnackBar(translate('ProductNotAvailableTime'), 3000, false);
    mainButtonData.addClass = 'grey';
    mainButtonData.text = `${translate('OnlyAvailableFrom')} ${product.available_since} до ${product.available_till}`;
    isTabsBlocked = true;
  } else if (tabsCountSum === 0) {
    mainButtonData.callback = () => toCartClickHandler(product);
  } else if (isTabsBlocked && isProductAvailable) {
    mainButtonData.callback = () => showSnackBar(translate('ChooseOneOfFreeProducts'), 3000, false);
    mainButtonData.addClass = 'grey';
  } else if (tabsCountSum > 0 && activeTabFromState < tabsCountSum - 1 && !isTabsBlocked) {
    mainButtonData.callback = () => tabOnclickHandler(activeTabFromState + 1);
  } else {
    mainButtonData.callback = () => toCartClickHandler(product, choosedAddons);
  }

  const tabsItems = tabs && tabs[0] ? tabs.map((item, index) => (
    <ProductHeaderTabItem
      key={item.id}
      itemData={item}
      tabIndex={index + 1}
      translate={translate}
      tabOnclickHandler={tabOnclickHandler}
      activeTabFromState={activeTabFromState}
      currentLanguage={currentLanguage}
      isTabsBlocked={isTabsBlocked}
      buttonCallBack={mainButtonData.callback}
    />
  )) : null;

  return (
    <>
      <StyledHeader className="modal-header clearfix">
        <div className="modal-header__title-wrap">
          <div className="modal-header__text">
            <div className="modal-header__category">{parentCatTitle}</div>
            <h1 className="modal-header__title">{title}</h1>
          </div>
          {isActiveProduct ? (
            <div className="modal-header__button-wrap">
              <MainBtn
                href="#"
                minWidth="130px"
                align="center"
                iconName="store"
                text={mainButtonData.text}
                clickHandle={mainButtonData.callback}
                addClass={mainButtonData.addClass}
              />
            </div>
          ) : (
            <div className="order-list__not-available">
              <p>{translate('ProductNotAvailable')}</p>
            </div>
          )}
        </div>
      </StyledHeader>
      <StyledTabsBlock className="tabs-block" tabsCountSum={tabsCountSum}>
        {tabsItems && tabsCountSum ? (
          <ul className="tabs-block__list">
            <li
              onClick={isTabsBlocked
                ? () => mainButtonData.callback()
                : () => tabOnclickHandler(0)}
              className={activeTabFromState === 0
                ? 'tabs-block__tab tabs-block__tab--active'
                : 'tabs-block__tab'}
            >
              <p className="tabs-block__number">1</p>
              <p className="tabs-block__button-name">{title}</p>
            </li>
            {tabsItems}
          </ul>
        ) : (
          <hr className="dashed padded tabs-block__line" />
        )}
      </StyledTabsBlock>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(React.memo(ProductModalHeader));

const StyledHeader = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  padding-top: 1.6rem;
  background-color: #0A0A0A;
  z-index: 2;
  padding-bottom: 1rem;

  .modal-header {
  
    &__title-wrap {
      display: flex;
      align-items: center;
    }
    
    &__text {
      flex-grow: 1;
    }
  
    &__category {
      color: var(--active-text-color);
      padding: 0 2.5rem;
    }
  
    &__title {
      color: var(--text-color);
      text-transform: none;
      font-weight: 200;
      padding-top: 1rem;
      font-size: 1.4rem;
    }
    
    &__button-wrap {
      position: fixed;
      bottom: 10px;
      left: 0;
      z-index: 12;
      width: 100%;
      padding: 0 15px;
      
      & .button {
        border-radius: 4px;
        box-shadow: 0 20px 15px 30px rgba(0,0,0, 1);
        width: 100%;
      }
    }
  }
  
  .grey {
    button {
      background-color: grey;
      
      &:hover {
        background-color: grey;
      }
    }
  }
  
@media all and (min-width: 768px) {
padding-top: 75px;
.modal-header {
  &__category {
    padding: 0 1rem 0 0;
  }
  
  &__title {
    font-size: 1.8rem;
    padding-top: 0;
  }
  
  &__title-wrap {
    display: flex;
    align-items: center;
  }

  &__button-wrap {
    position: static;
    width: auto;
    min-width: 130px;
  }
  
  &__button-wrap {
      position: static;
      width: auto;
      padding: 0;
      
      
      .button {
        box-shadow: none;
        border-radius: 4px;
      }
    }
  }
}

@media all and (min-width: 769px) {
  .modal-header {
    &__button-wrap {
      margin-right: 8rem;
    }
  }
}

@media all and (min-width: 1450px) {
  .modal-header {
    &__button-wrap {
      margin-right: 0;
    }
  }
}
`;

const StyledTabsBlock = styled.div`
  --text-color: #8E8E8E;
  --active-text-color: #FFFFFF;
  --active-background-color: #313131;

  padding-top: .5rem;

  .tabs-block {
    &__line {
      display: none;
    }
  
    &__list {
      display: flex;
    }
    
    &__tab {
      padding: .7rem;
      font-size: .9rem;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: ${(props) => (`${100 / props.tabsCountSum}%`)};
      text-transform: uppercase;
      color: var(--text-color);
      border: 1px solid var(--active-background-color);
      box-sizing: border-box;
      background-color: #1C1C1C;
      display: flex;
      justify-content: center;
      
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
      
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      
      &--active {
        color: var(--active-text-color);
        background-color: var(--active-background-color);
        
        & .tabs-block__number::after {
          background-color: var(--active-text-color);
        }
      }
      
      &:hover {
        cursor: pointer;
        background-color: var(--active-background-color);
      }
    }
    
    &__number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: .5rem;
      
      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        margin-left: .5rem;
        border-radius: 50%;
        background-color: var(--text-color);
      }
    }
    
    &__button-name {
      display: none;
    }
  }
  
  
@media all and (min-width: 768px) {
  padding-top: 2rem;
  .tabs-block {
    &__line {
      display: block;
    }
  
    &__tab {
      padding: 1.4rem;
      justify-content: flex-start;
    }
    
    &__button-name {
      display: flex;
      align-items: center;
    }
  }
}
`;
