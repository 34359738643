import * as headers from './headers';
import { axiosInstance } from './axiosInstance';

const AllergensApi = {
  getAllergenList() {
    return axiosInstance.post('allergens',
      {},
      { headers: headers.json });
  },
};

export default AllergensApi;
