import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom';

import ModalWrapper from '../components/shared/ModalWrapper';
import getlanguagePrefix from '../modules/shared/getLanguagePrefix';
import getProductByAlias from '../modules/shared/getProductByAlias';
import ProductItemModal from '../components/product/ProductItemModal';
import getProductAllergensByIds from '../modules/shared/getProductAllergensByIds';
import getProductIngredientsByIds from '../modules/shared/getProductIngredientsByIds';
import getProductTabsDataByIds from '../modules/shared/getProductTabsDataByIds';
import * as actions from '../actions/actions';
import LoadingSuspense from '../components/shared/LoadingSuspense';


class ProductContainer extends React.Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    window.scrollTo(0, 0);
  }

  addToCartHandler = (product, addons) => {
    if (addons) {
      this.props.actions.cartAdd(product.id, addons);
    } else {
      this.props.actions.cartAdd(product.id);
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Add To Cart',
        eventCategory: 'Customer Actions',
        eventAction: 'Add To Cart',
        eventLabel: `${product.id}`,
        eventValue: `${product.price}`,
      });
    }
    this.props.actions.addedProduct(true);
    this.closeModal();
  };

  isEmpty = (obj) => Object.entries(obj).length === 0;

  fetchData() {
    return Promise.all([
      this.props.actions.getAllergens(),
      this.props.actions.getIngredients(),
    ]);
  }

  closeModal() {
    const { lastLocation, history, currentLanguage } = this.props;
    const lang = getlanguagePrefix(currentLanguage);
    if (lastLocation) {
      return history.goBack();
    }
    return history.push(`${lang}/`);
  }

  render() {
    const { match } = this.props;
    const aliasFromRoute = match.params.number;
    const productAlias = aliasFromRoute || null;
    const productData = getProductByAlias(aliasFromRoute, this.props.categories);
    const allergensData = productData.allergens
      ? getProductAllergensByIds(productData.allergens, this.props.allergens) : null;
    const ingredientsData = productData.ingredients
      ? getProductIngredientsByIds(productData.ingredients, this.props.ingredients) : null;
    const mainTasteData = productData.mainTaste
      ? getProductIngredientsByIds(productData.mainTaste, this.props.ingredients) : null;
    const getProductTabsData = (ids) => getProductTabsDataByIds(ids, this.props.categories);
    const isSet = !!parseInt(productData.is_set, 10);
    const isLunchBox = !!parseInt(productData.is_lunchbox, 10);
    const setOrLunchBoxInnerProducts = isSet || isLunchBox
      ? getProductTabsDataByIds(productData.products, this.props.categories)
      : [];

    return productAlias ? (
      <>
        {!this.isEmpty(productData) ? (
          <ProductItemModal
            productData={productData}
            allergensData={allergensData}
            ingredientsData={ingredientsData}
            categories={this.props.categories}
            getProductTabsData={getProductTabsData}
            currentLanguage={this.props.currentLanguage}
            mainTasteData={mainTasteData}
            isSet={isSet}
            goBack={this.closeModal}
            isLunchBox={isLunchBox}
            setOrLunchBoxInnerProducts={setOrLunchBoxInnerProducts}
            toCartClickHandler={this.addToCartHandler}
            showSnackBar={this.props.actions.showSnackBar}
            user={this.props.user}
            removeFromFavourites={this.props.actions.removeFromFavourites}
            addToFavourites={this.props.actions.addToFavourites}
            translate={this.props.translate}
          />
        ) : <LoadingSuspense />}
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories ? state.categories : null,
  allergens: state.allergens ? state.allergens : null,
  ingredients: state.ingredients ? state.ingredients : null,
  currentLanguage: getActiveLanguage(state.locale).code,
  user: state.user ? state.user : null,
  translate: getTranslate(state.locale),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLastLocation(ProductContainer)));
